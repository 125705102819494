<template>
  <div class="container">
    <div class="loginbox">
      <!--左侧图片  -->
      <div class="img">
        <span></span><br />
        <span>铭智生科<br />官网后台管理</span>
      </div>
      <div class="admin">
        <div class="title">—— 欢迎登录 ——</div>
        <el-form ref="formData" :model="user" label-width="80px" :rules="rules">
          <el-form-item label-width="0" prop="username">
            <el-input v-model="user.username" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item label-width="0" class="password" prop="password">
            <el-input v-model="user.password" placeholder="请输入密码" @keyup.enter.native="handleLogin"></el-input>
          </el-form-item>
          <el-button type="primary" @click.native="handleLogin">登录</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      user: {
        username: 'admin',
        password: '123456'
      },
      rules: {
        username: [
          { required: true, message: '账号不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    async handleLogin () {
      this.$refs.formData.validate()
      // this.loading = true
      await this.$store.dispatch('user/login', this.user)
      this.$router.push('/header')
      // this.loading = false

    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 1080px;
  background: url("../../assets/编组 5.png") no-repeat;
  background-position: center;
}
.loginbox {
  display: flex;

  .img {
    width: 584px;
    height: 851px;
    background: url("../../assets/编组 4.png") no-repeat;
    position: relative;
    left: 20%;
    padding-top: 8%;
    padding-left: 8%;

    span:nth-child(1) {
      display: inline-block;
      width: 129px;
      height: 55px;
      background: url("../../assets/编组 2.png") no-repeat;
      background-size: 100%;
      margin-bottom: 30px;
    }
    span:nth-child(3) {
      width: 210px;
      height: 90px;
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 45px;
      letter-spacing: 3px;
    }
  }
  .admin {
    text-align: center;
    margin: auto;
    position: relative;
    right: 4.88%;
    width: 641px;
    height: 647px;
    background: #ffffff;
    box-shadow: 0px 14px 58px 0px rgba(150, 173, 249, 0.29);
    padding-top: 62px;
    .title {
      display: inline-block;
      width: 50%;
      height: 45px;
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4a71af;
      line-height: 45px;
      margin-bottom: 65px;
    }
  }
}

/deep/ .el-input__inner {
  width: 420px;
  height: 64px;
  border-radius: 16px;
  background: #f9f9f9;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a71af;
  line-height: 33px;
  text-align: center;
  border: 0;
}
//修改placeholder提示文字颜色
/deep/ input::-webkit-input-placeholder {
  -webkit-text-fill-color: #4a71af;
  opacity: 0.55;
}
.password {
  margin-top: 30px;
}
/deep/ .el-button {
  width: 420px;
  height: 64px;
  background: #4a71af;
  border-radius: 16px;
  border: 0;
  margin-top: 50px;
  span {
    width: 48px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 33px;
  }
}
::v-deep .el-form-item__error {
  position: relative;
}
</style>